<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title style="width: 100%; display: block; text-align: center;" class=" align-center justify-center">
          <router-link style="display: inline-block;" to="/" class=" align-center">
            <v-img :src="require('@/assets/images/logos/logo-small.png')" max-width="130px" alt="logo" contain
              class="me-3 "></v-img>
          </router-link>
          <router-link style="display: ;" to="/" class=" align-center">
            <h2 class="text-2xl font-weight-semibold">
              SIOPAK TEGAL
            </h2>
          </router-link>
        </v-card-title>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field v-model="email" outlined label="Username / Email" placeholder="xxx / xxx@xxx.com" hide-details
              class="mb-3"></v-text-field>

            <v-text-field v-model="password" outlined :type="isPasswordVisible ? 'text' : 'password'" label="Password"
              placeholder="············" :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details @click:append="isPasswordVisible = !isPasswordVisible"></v-text-field>
            <VueRecaptcha :sitekey="siteKey" :load-recaptcha-script="true" @verify="handleSuccess" @error="handleError">
            </VueRecaptcha>
            <div class="d-flex align-center justify-space-between flex-wrap">
              <!-- <v-checkbox label="Remember Me" hide-details class="me-3 mt-1">
              </v-checkbox> -->

              <!-- forgot link -->
              <!-- <a href="javascript:void(0)" class="mt-1">
                Lupa Password?
              </a> -->
            </div>

            <v-btn block color="primary" class="mt-6" @click="login()" :loading="loading">
              Login
            </v-btn>
          </v-form>
        </v-card-text>
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            Belum punya akun?
          </span>
          <router-link :to="{ name: 'register' }">
            Register
          </router-link>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import axios from 'axios'
import { VueRecaptcha } from 'vue-recaptcha';

const apiRoot = process.env.VUE_APP_APIROOT
export default {
  components: {
    VueRecaptcha
  },
  setup() {
    const siteKey = process.env.VUE_APP_CAPTCHA_KEY

    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')

    return {
      siteKey,
      isPasswordVisible,
      email,
      password,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  data: () => ({
    loading: false,
    recaptcha: ''
  }),
  methods: {
    handleError(){},
    handleSuccess(response) {
      this.recaptcha = response
    },
    login() {
      this.loading = true
      const fmData = new FormData()
      fmData.append('username', this.email)
      fmData.append('password', this.password)
      fmData.append('recaptcha', this.recaptcha)
      axios
        .post(`${apiRoot}/Authentication/login`, fmData)
        .then(response => {
          if (response.data.code === 200) {
            const data = {
              token: response.data.token,
            }
            this.$store.dispatch('login', data)
            this.$router.push('/')
          } else {
            alert(response.data.message)
          }
          this.loading = false
        })
        .catch(error => {
          alert(error)
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
